import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import t, { changeLang } from "@/utils/i18n";
import { Topping, list } from "@/data/data.js";
import { countryCodeObject } from "@/config/config";
import qs from "qs";
import "./styles.scss";

function Home(props) {
  const { search } = props.location;
  const source = qs.parse(search.replace(/^\?/, ""));
  const lang = source.lang;
  const [loading, setLoading] = useState(true);
  const [langState, setLangState] = useState("en");
  console.log("lang", lang);

  async function getLang() {
    const url = "https://gh5.gameboxcn.xyz/ip/code";
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    return json;
  }

  useEffect(() => {
    if (lang) {
      changeLang(lang);
      setLangState(lang);
      setLoading(false);
    } else {
      getLang()
        .then((res) => {
          console.log(res, countryCodeObject[res.code]);
          if (res?.code) {
            const _lang = countryCodeObject[res.code] || "en";
            setLangState(_lang);
            changeLang(_lang);
          }
        })
        .catch((e) => {
          console.log("error", e);
        })
        .then(() => {
          console.log("finally");
          setLoading(false);
        });
    }
  }, [lang]);

  useEffect(() => {
    if (!loading) {
      window.googletag.cmd.push(function () {
        window.googletag.display("div-gpt-ad-1717752002094619-0");
      });
    }
  }, [loading]);

  if (loading) {
    return <></>;
  }

  return (
    <div className="page-container">
      <Header />
      <div className="page-content">
        {Topping.enable && (
          <>
            <Link
              className="list"
              to={
                langState === "en"
                  ? "/Fireboy-and-Watergirl-1-Forest-Temple"
                  : "/Fireboy-and-Watergirl-1-Forest-Temple?lang=" + langState
              }
            >
              <div className="iframes">
                <iframe
                  title={Topping.key}
                  id={Topping.key}
                  src={Topping.src}
                  name="myiFrame"
                  scrolling="no"
                  height="600px"
                  width="100%"
                  allowFullScreen={true}
                ></iframe>
                <div className="name">
                  <img
                    className="thumb"
                    src={require(`../../assets/${Topping.key}.jpg`)}
                    alt={Topping.name}
                  />
                  <h1 className="title">{Topping.name}</h1>
                </div>
                <div className="desc">{t.t(`${Topping.key}.desc`)}</div>
              </div>
            </Link>
          </>
        )}

        <div className="list-box">
          {list.map((item, index) => {
            return (
              <div key={index}>
                {index === 1 ? (
                  <div
                    id="div-gpt-ad-1717752002094619-0"
                    className="div-gpt-ad"
                  ></div>
                ) : (
                  <></>
                )}

                <a
                  className="list"
                  key={index}
                  href={
                    langState === "en"
                      ? "/" + item.key
                      : "/" + item.key + "?lang=" + langState
                  }
                >
                  <div className="list-t">
                    <div className="thumb-box">
                      <img
                        className="thumb"
                        src={require(`../../assets/${item.key}.jpg`)}
                        alt={item.name}
                      />
                    </div>
                    <div className="info">
                      <div className="game-name">{item.name}</div>
                      <div className="star">
                        <img
                          src={require("../../assets/star.jpg")}
                          alt="star"
                        />
                      </div>
                      <div className="lever">
                        <img
                          src={require("../../assets/lever.jpg")}
                          alt="lever"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="list-b">
                    <div className="desc">{t.t(`${item.key}.desc`)}</div>
                    <div className="link-btn">{t.t("common.playNow")}</div>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
