import React, { useEffect, useState } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Header from "@/pages/components/Header";
import Footer from "@/pages/components/Footer";
import { list } from "@/data/data";
import t, { changeLang } from "@/utils/i18n";
import defaultIcon from "@/assets/img_default.png";
import Description from "@/pages/components/Description";
import qs from "qs";
import "../Home/styles.scss";

function Detail() {
  const history = useHistory();
  const { id } = useParams();
  const { search } = useLocation();
  const source = qs.parse(search.replace(/^\?/, ""));
  const lang = source.lang || "en";
  const [detail, setDetail] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const item = list.find((l) => l.key === id);
    document.title = item.name + " - " + window.location.host;
    setDetail(item);
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    changeLang(lang);
    setLoading(false);
  }, [lang]);

  useEffect(() => {
    if (!loading) {
      window.googletag.cmd.push(function () {
        window.googletag.display("div-gpt-ad-1717752002094619-0");
      });
    }
  }, [loading]);

  if (loading) {
    return <></>;
  }

  return (
    <div className="page-container">
      <Header />

      <div className="page-content">
        <div className="detail-content">
          <div className="nav">
            <div
              className="link"
              onClick={() => {
                history.goBack();
              }}
            >
              {t.t("common.home")}
            </div>
            <span>»</span>
            <span>{id}</span>
          </div>
          <div className="iframes">
            <iframe
              title={detail.key}
              id={detail.key}
              src={detail.src}
              name="myiFrame"
              scrolling="no"
              height="600px"
              width="100%"
              allowFullScreen={true}
            ></iframe>
            <div className="name">
              <div className="thumb-box">
                <img
                  className="thumb"
                  src={
                    detail.key
                      ? require(`../../assets/${detail.key}.jpg`)
                      : defaultIcon
                  }
                  alt={detail.name}
                />
              </div>

              <h1 className="title">{detail.name} Unblocked</h1>
            </div>

            <div
              id="div-gpt-ad-1717752002094619-0"
              className="div-gpt-ad"
            ></div>

            <div className="desc">{t.t(`${detail.key}.desc`)}</div>
            <div className="lever">
              <img src={require("../../assets/lever.jpg")} alt="lever" />
            </div>
            <div className="star">
              <img src={require("../../assets/star.jpg")} alt="star" />
            </div>
            <div className="information">
              <Description id={id} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Detail;
